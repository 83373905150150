<template>
  <v-app v-if="invoice !== null">
    <v-container>
      <div class="text-center mb-3">
        <img
          src="./assets/logotype.png"
          alt="Voictech"
        />
      </div>

      <v-card class="mb-5">
        <v-card-title>
          <h2>Betala faktura via kort</h2>
        </v-card-title>

        <v-card-text>
          <template v-if="invoice === false">
            <p>Du behöver ha en länk med ett giltigt fakturanummer i sig för att kunna betala fakturan via kort.</p>
          </template>

          <template v-if="invoice !== false">
            <p>
              <strong>Fakturanummer: </strong> {{ invoice.id }} <br />
              <strong>Totalbelopp: </strong> {{ invoice.priceTotal | price }} <br />
              <strong>Tidigare inbetalt: </strong> {{ invoice.pricePaid | price }}
            </p>

            <p><strong>Återstår att betala: </strong> {{ (invoice.priceTotal - invoice.pricePaid) | price }}</p>

            <v-btn
              target="_blank"
              :href="'https://minasidor.voicetech.nu/' + invoice.token + '/' + invoice.id"
            >
              Öppna fakturan
            </v-btn>
          </template>
        </v-card-text>
      </v-card>

      <v-card v-if="invoice && !invoice.isPaid && stripe.publishableKey && stripe.elementsOptions.clientSecret">
        <v-card-text>
          <p>Fyll i dina kortuppgifter för att betala fakturan.</p>

          <stripe-element-payment
            ref="stripeElementPayment"
            :pk="stripe.publishableKey"
            :elements-options="stripe.elementsOptions"
            :confirm-params="stripe.confirmParams"
            class="mb-5"
          />

          <v-btn
            color="primary"
            @click="makePayment"
            @token="tokenCreated"
          >
            Betala {{ (invoice.priceTotal - invoice.pricePaid) | price }}
          </v-btn>
        </v-card-text>
      </v-card>

      <template v-if="invoice !== null && invoice !== false">
        <v-alert
          v-if="invoice.isPaid"
          type="success"
        >
          Fakturan har redan betalats till fullo.
        </v-alert>
      </template>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios'
import { StripeElementPayment } from '@vue-stripe/vue-stripe'

export default {
  components: {
    StripeElementPayment,
  },

  data: () => ({
    invoice: null,
    stripe: {
      publishableKey: null,
      elementsOptions: {
        paymentMethodOrder: ['card'],
        clientSecret: null,
        appearance: {
          locale: 'sv',
          hidePostalCode: true,
        },
      },
      confirmParams: {
        return_url: document.location,
      },
    },
  }),

  async created() {
    const urlMatch = window.location.pathname.matchAll(/^\/([A-Za-z0-9]+)\/([0-9]+)$/g).next().value

    if (!urlMatch) {
      this.invoice = false
    } else {
      const invoiceToken = urlMatch[1]
      const invoiceId = urlMatch[2]

      try {
        this.invoice = (await axios.get('/api/invoices/' + invoiceToken + '/' + invoiceId)).data
      } catch {
        this.invoice = false
      }

      if (this.invoice && !this.invoice.isPaid) {
        this.stripe.elementsOptions.clientSecret = this.invoice.paymentIntentClientSecret
      }
    }

    const data = (await axios.get('/api/data')).data

    this.stripe.publishableKey = data.stripePublishableKey
  },

  methods: {
    makePayment() {
      this.$refs.stripeElementPayment.submit()
    },
  },
}
</script>

<style>
.no-link-color a {
  text-decoration: none !important;
}

.container {
  max-width: 1000px;
}
</style>
